.my_counter_a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
}

.my_counter {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.2em 0.4em;
}



.mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 0 10px;
    z-index: 30;
}

.mobile-navbar .nav-link {
    flex-shrink: 0;
    font-size: 0.9rem;
    margin-right: 5px;
}
