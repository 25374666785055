.page-body-wrapper{
    min-height: 100vh
}

.sidebar {
    z-index: 50;
    .nav {
        .nav-item {
            &:nth-child(2).active {
                background: $sidebar-light-menu-active-bg;
                & > .nav-link {
                    i {
                        color: $white;
                    }
                    .menu-title {
                        color: $white;
                        font-family: "Open Sans", sans-serif;
                        font-weight: 600;
                    }
                }
            }

            &.nav-profile .nav-link .nav-profile-image {
                width: 80px;
                height: 80px;
                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}

.jq-toast-wrap {
    width: 260px;
}

.jq-toast-single {
    font-size: 14px;
}

.tooltip-inner {
    a {
        color: white;
        text-decoration: none;
    }
}

select.form-control {
    color: #212529;
    &.select-placeholder {
        color: $input-placeholder-color;
    }
}

.datepicker-dropdown {
    z-index: 1031 !important;
}

.footer {
    background-color: #4d4d4d;
    a {
        color: white;
    }
}

@media (min-width: 992px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
    left: -92px;
    &:before {
        left: 126px;
    }
  }
}

@media (max-width: 576px) {
    .btn {
        &.btn-lg {
            font-size: 13px;
            padding: 16px 8px;
        }
    }
}

