
////////// SIDEBAR ////////
$sidebar-width-lg: 260px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: $white;
$sidebar-light-menu-color: #3e4b5b;
$sidebar-light-submenu-color: #3e4b5b;
$sidebar-light-menu-active-bg: #1a55e3;
$sidebar-light-menu-active-color: $white;
$sidebar-light-menu-hover-bg: darken($sidebar-light-bg, 1%);
$sidebar-light-menu-hover-color: $white;
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 20%);
$sidebar-light-submenu-active-color:#dfe2e8;
$sidebar-light-menu-icon-color: #6a6b83;
$sidebar-light-menu-title-color: #141212;
$sidebar-light-menu-arrow-color: #423a8e;
$sidebar-light-menu-border-color: #f2edf3;

$sidebar-dark-bg: #18151e;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #1a55e3;
$sidebar-dark-menu-active-color: $white;
$sidebar-dark-menu-hover-bg: $sidebar-dark-menu-active-bg;
$sidebar-dark-menu-hover-color: $white;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 30%);
;
$sidebar-dark-menu-icon-color: $white;
$sidebar-dark-menu-arrow-color: $white;

$sidebar-menu-font-size: $default-font-size;
$sidebar-menu-padding-y: 10px;
$sidebar-menu-padding-x: 2.25rem;

$sidebar-submenu-font-size: .8125rem;
$sidebar-submenu-item-padding: .75rem 2rem .75rem 15px;

$sidebar-icon-font-size: 20px;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;
$icon-only-menu-bg-dark: $sidebar-dark-menu-hover-bg;

///////// SIDEBAR ////////

$bullet-line-list-shape-bg: color(white) !default;