#homepage-banner-image {
  height: 100vh;
  min-height: 500px;
  background-image: url('~/vendors/images/banner/ensemble-619255_1920-i.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.front-page-buttons {
    .btn {
        font-size: 1.2rem;
        padding: 20px;
    }
}

.details-wrapper {
    padding-top: 100px;
    img {
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter: drop-shadow(5px 5px 5px #222);
        width: 100%;
    }
}

body.desktop {
    .details-wrapper {

        img {
          -webkit-transition: all 0.8s ease;
          -moz-transition: all 0.8s ease;
          transition: all 0.8s ease;
        }

        a:hover {
            img {
                transform: scale(1.2,1.2);
            }
        }
    }
}

.footer-front-page {
    padding-top: 0;
}

@media ( max-width: 768px ) {
    #homepage-banner-image {
        .banner-content {
            padding-top: 0;
            text-align: center;
        }
    }
    .content-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}