//@import "~bootstrap/scss/bootstrap.scss";

@import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min";
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.min.css";
@import "~jquery-tags-input/dist/jquery.tagsinput.min.css";
@import "~jquery-toast-plugin/dist/jquery.toast.min.css";
@import "~flag-icon-css/css/flag-icon.min.css";
@import "~fullcalendar/dist/fullcalendar.min.css";
@import "~tinymce/skins/ui/oxide/skin.min.css";
@import "~tinymce/skins/ui/oxide/content.min.css";
@import "~dragula/dist/dragula.min.css";

$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome";

@import "plusadmin/main/plusadmin-style";
@import "custom";

// Local
@import "general.scss";
@import "pages/homepage";
@import "pages/mainpage";
