.project-list-showcase {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;

    .project-grid {
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 40px;
        overflow: hidden;

        .img-holder {
            width: 100%;
            height: 140px;
            border-radius: 4px 4px 0 0;

            &.img-bg-1 {
                background: none;
                background-size: cover;
            }

            &.img-bg-2 {
                background: none;
                background-size: cover;
            }

            &.img-bg-3 {
                background: none;
                background-size: cover;
            }
        }

        .project-grid-inner {
            border: 1px solid $border-color;
            border-radius: 0 0 4px 4px;
            padding: 30px 25px 30px 25px;
        }

        .project-title {
            margin-bottom: 0;
            font-weight: 500;
        }

        .project-location {
            margin-bottom: 0;
            font-weight: 500;
            color: lighten($body-color, 5%);
            margin-bottom: 15px;
        }

        .action-tags {
            font-weight: 500;
            color: $body-color;
        }
    }
}