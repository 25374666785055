.accordion-button {
    display: block;
    cursor: pointer;
    &.disabled {
        cursor: default;
        pointer-events: none;
        .text-dark {
            color: rgba(var(--bs-dark-rgb), 0.6) !important;
        }
    }
}

.sidebar .nav .nav-item {
    display: block;
    cursor: pointer;
}

.order-input {
    input {
        max-width: 100%;
    }
}

.list-wrapper {
    .song {
        &.selected {
            .mdi-check-circle-outline::before {
                content: "\F5E0" !important;
            }
        }
        &:not(.selected){
            .mdi-check-circle::before {
                content: "\F5E1" !important;
            }
        }
    }
}

.form-group.required {
    label:after {
        content:"*";
    }
}

a#activeEnsembleDropdown {
    color: $dark;
    text-decoration: none;
}

.active-ensemble-dropdown {
    .dropdown-divider {
        margin: 0;
    }
}
.unread-count-container {
    position: relative;
    .unread-count {
        color: white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: #ff0854;;
        text-align: center;
        line-height: 18px;
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        right: -10px;
        bottom: 0;
    }
}

.new-message {
    font-weight: bold;
}


.youtube-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.table td.table-text {
    white-space: normal;
}

.event-list-item {
    .event-date {
        i {
            margin-right: 5px;
        }
    }
    .event-name {
        margin-bottom: 0;
        a {
            color: $body-color;
            text-decoration: none;
        }
    }
}

#set-thresholds-form {
    i.remove {
        font-size: 20px;
        color: red;
        cursor: pointer;
    }
}

#event-check-list {
    .card-title {
        padding-bottom: 24px;
        border-bottom: 1px solid $border-color;
    }
    .user_row {
        padding-top: 8px;
        padding-bottom: 8px;
        .event-check-list-username {
            border-bottom: 1px solid $border-color;
        }

    }
}

.event-check-list-user-declaration,
.event-view-declaration-label {
    text-transform: lowercase;
    color: darkgrey;
    &:not(:empty) {
        &::before {
          content: " - ";
        }
    }
}

.event-check-list-declaration-comment {
    font-style: italic;
    &:not(:empty) {
        &::before, &::after {
          content: '"';
        }
    }
}

.two-tabs-switch {
    .tickets-tab-switch {
      .nav-item {
          width: 50%;
           @include media-breakpoint-down(sm) {
              width: 100%;
          }
      }
    }
}

.nav-scroller-narrow {
    .tickets-tab-switch {
        padding: 0;
        .nav-item .nav-link {
            padding-left: 10px;
            padding-right: 10px;
            .badge {
                z-index: 2;
            }
        }
    }
}

#song-voices {
    .song-voice-item {

        border-bottom: 1px solid #e4e9f0;

        &:first-of-type {
            .list-move-up {
                visibility: hidden;
                pointer-events: none;
            }
        }
        &:last-of-type {
            .list-move-down {
                visibility: hidden;
                pointer-events: none;
            }
        }

        .list-move-up, .list-move-down {
            padding-right: 12px;
            color: rgb(160, 160, 160);
            cursor: pointer;
            i {
                font-size: 35px;
                line-height: 25px;
            }
        }

        .repeater-delete {
            font-size: 22px;
            line-height: 36px;
            color: red;
            cursor: pointer;
        }
    }
}

#event-songs-list {

    .selectedSongs {
        &:not(.d-none){
            &:first-of-type {
                .list-move-up {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
            &.last-of-type {
                .list-move-down {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
        }

        .list-move-up, .list-move-down {
            padding-right: 12px;
            color: rgb(160, 160, 160);
            cursor: pointer;
            i {
                font-size: 35px;
                line-height: 35px;
            }
        }
        .song-order {
            display: block;
            padding-right: 12px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
        .dragula-handle {
            color: rgb(160, 160, 160);
            i {
                font-size: 25px;
            }
        }
    }
}

.loading img {
    max-height: 50px;
}

body.mobile {
    .w-100-mobile {
        width: 100%;
    }
}

#overlay {
    display: none;
}
body.overlay {
   overflow: hidden;
   #overlay {
      display: block;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 40;
      position: fixed;
      height: 100%;
      width: 100%;
   }
}

@media (max-width: 600px) {
    .card-body.event-calendar-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .card-title {
            padding-left: 10px;
        }

        .fc {
            .fc-month-view {
                .fc-content-skeleton {
                    table {
                        tr td {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }

                .fc-event {
                    padding-left: 2px;
                }

                .fc-time {
                    display: none;
                }

                .fc-event-container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}


table.table-wrap {
    width: 100%;
    table-layout: fixed;
}

table.table-wrap td {
    white-space: wrap;
}

table.table-wrap td.table-row-title {
    width: 40%;
    word-wrap: break-word;
}

.strict-voice-search-inputs {
    display: inline-block;
    line-height: 14px;
    font-weight: 800;
}

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  z-index: 1000;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: white;
  }
}


// Statistics

.wrapped-button-names {
    word-break: break-word;
    overflow-wrap: break-word;
}

.statistics-table-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr)); /* adaptable columns */
    border: 1px solid #ccd3dd;
    padding: 10px;
    width: 100%;
}

.statistics-table-header, .statistics-table-cell {
    padding: 10px;
    border: 1px solid #ccd3dd;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
}

.statistics-table-header {
    background-color: #1a55e3;
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccd3dd;
}

.sort-icons {
    display: flex;
    gap: 5px;
}

.sort-icons a {
    color: white;
    text-decoration: none;
}

.statistics-table-row {
    display: contents; /* it saves grid */
    &:not(:last-child) {
        .statistics-table-cell {
            border-bottom: none;
        }
    }
    .statistics-table-cell {
        &:not(:first-child) {
            border-left: none;
        }
    }
}

.statistics-user-events {
    display: block;
    grid-column: span 6; /* stretches the entire width of the table */
    width: 100%;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccd3dd;
}

.multiple-stats-table {
    width: 100%;
    border-collapse: collapse;
}

.multiple-stats-table th, .multiple-stats-table td {
    border: 1px solid #ccd3dd;
    padding: 8px;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
}

/* responsivenes - adapted to small screens */
@media (max-width: 1024px) {
    .statistics-table-container {
        grid-template-columns: repeat(6, minmax(80px, 1fr)); /* smaller columns */
    }
    .statistics-table-header, .statistics-table-cell {
        font-size: 14px; /* smaller text */
        padding: 5px;
    }
}

@media (max-width: 768px) {
    .statistics-table-container {
        grid-template-columns: repeat(6, minmax(60px, 1fr));
    }
    .statistics-table-header, .statistics-table-cell {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .statistics-table-container {
        grid-template-columns: repeat(6, minmax(50px, 1fr)); /* minimum widths of cells */
    }
    .statistics-table-header, .statistics-table-cell {
        font-size: 11px;
        padding: 3px;
    }
}

.attendance-status-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    font-size: 14px;
    max-width: 90%;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
}

@media (max-width: 768px) {
    .multiple-stats-table .badge {
        font-size: 12px;
        padding: 4px 6px;
    }

    .menu-fixed-mobile {
        right: 5px;
        margin-top: 30px;
    }
}

@media (max-width: 480px) {
    .multiple-stats-table .badge {
        font-size: 10px;
        padding: 3px 5px;
        max-width: 80%;
    }
}
