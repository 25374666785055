/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type-1;
  font-weight: $font-weight-regular;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  border-right: 1px solid $border-color;
  transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
    width: inherit;

    .nav-item-head {
      padding: 0 $sidebar-menu-padding-x;
      font-size: 11px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .nav-item {
      padding: 0 $sidebar-menu-padding-x;
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1.2;
            margin-right: 10px;
            .rtl & {
              margin-right: 0;
              margin-left: 10px;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: 16px;
            margin-left: auto;
            color: $sidebar-light-menu-arrow-color;
            opacity: 0.36;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            &:before {
              content: "\f142";
              font-size: inherit;
              color: inherit;
              .rtl & {
                content: "\f141";
              }
            }
            + .menu-icon {
              margin-left: 0.25rem;
              .rtl & {
                margin-left: 0;
                margin-right: 0.25rem;
              }
            }
          }
        }

        small {
          display: block;
        }

        .menu-title {
          color: $sidebar-light-menu-title-color;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          white-space: normal;
        }

        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }

        .sidebar-brand-logomini {
          display: none;
        }

        &[aria-expanded="true"] {
          .menu-arrow {
            &:before {
              content: "\f140";
            }
          }
        }
      }

      form {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              padding-left: 16px;
              height: 30px;
              background: #f7f8f9;
              color: #8e94a9;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              .rtl & {
                border-radius: 0;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
              }
            }
          }
          .form-control {
            font-size: 10px;
            padding: 0;
            height: 30px;
            background: #f7f8f9;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            &::placeholder {
              color: #a0a0a0;
            }
            .rtl & {
              border-radius: 0;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
            }
          }
        }
      }

      &:not(:nth-child(2)).active {
        background: $sidebar-light-menu-active-bg;
        > .nav-link {
          .menu-title {
            color: $sidebar-light-menu-active-color;
            font-family: $type-1;
            font-weight: $font-weight-semibold;
            .sidebar-icon-only & {
              color: $black;
            }
          }
          i {
            color: $sidebar-light-menu-active-color;
          }
        }
        .sub-menu {
          .nav-link {
            color: $sidebar-light-submenu-active-color;
            &.active {
              color: $white;
              .sidebar-icon-only & {
                color: $black;
              }
            }
          }
        }
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          display: block;
          padding: 27px 0 0 0;
          .nav-profile-image {
            width: 65px;
            height: 65px;
            margin: auto;
            img {
              width: 65px;
              height: 65px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 20px;
      margin-left: 9px;
      margin-top: 0;
      list-style: none;
      border-left: 1px solid $border-color;
      .rtl & {
        border: 0;
        margin-right: 9px;
        border-right: 1px solid $border-color;
      }
      .sidebar-icon-only & {
        margin-left: 36px;
        padding: 0;
      }

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &.active {
            color: $sidebar-light-menu-active-color;
            font-weight: $font-weight-semibold;
            background: transparent;
          }
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;
    .nav {
      .nav-item {
        .nav-link {
          color: $sidebar-dark-menu-color;
          i {
            color: inherit;
            &.menu-icon {
              color: $sidebar-dark-menu-icon-color;
            }
            &.menu-arrow {
              color: $sidebar-dark-menu-arrow-color;
              &:before {
                color: inherit;
              }
            }
          }
          .menu-title {
            color: inherit;
          }
        }
        &.active {
          > .nav-link {
            .menu-title {
              color: $sidebar-dark-menu-active-color;
            }
            i {
              color: theme-$white;
            }
          }
        }
        &:hover {
          color: white;
        }
        &.sidebar-actions {
          &:hover {
            background: initial;
            .nav-link {
              color: initial;
            }
          }
        }
      }
      .nav-item-head {
        color: $white;
      }
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            &:not(.nav-category):not(.nav-profile) {
              > .nav-link {
                color: $sidebar-dark-menu-hover-color;
              }
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;
            &:before {
              color: lighten($sidebar-dark-submenu-color, 10%);
            }
            &.active {
              color: $sidebar-dark-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-dark-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}
